import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Message, Pagination} from "../models/message";
import {Chat} from "../models/chat";
import {environment} from "../../../../environments/environment";

@Injectable()
export class ChatService{
  constructor(private httpClient: HttpClient) {
  }
  chats(){
    return this.httpClient.get<Chat[]>(`${environment.apiHost}/chats`)
  }
  chatMessages(chatId: string){
    return this.httpClient.get<Pagination<Message>>( `${environment.apiHost}/chats/${chatId}/messages`)
  }
}
