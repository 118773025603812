import {Component, inject, OnInit} from '@angular/core';
import {Platform} from "@ionic/angular";
import {PushNotificationService} from "./share/services/push-notification.service";
import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {MatDialog} from "@angular/material/dialog";
import {NotificationComponent} from "./share/components/notification/notification.component";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  message: any;
  readonly dialog = inject(MatDialog);
  constructor(private platform: Platform,
              private pushNotificationService: PushNotificationService) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.initializePushNotifications()
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
      } else {
        this.initWebPush();
      }
    });
  }

  initializePushNotifications() {
    PushNotifications.requestPermissions().then(result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        console.log('token', token.value)
        alert('Push registration success, token: ' + token.value);
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        alert('Push action performed: ' + JSON.stringify(notification));
      }
    );
  }

  initWebPush() {
    this.registerServiceWorker()
    this.requestPermission();
    this.listen()
  }

  requestPermission() {
    this.pushNotificationService.requestPermission()
      .then((token) => {
        console.log('notificationToken', token)
        this.pushNotificationService.subscribe(token).subscribe()
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
  }

  registerServiceWorker() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch(err => {
          console.error('Service Worker registration failed:', err);
        });
    }
  }

  listen() {
    this.pushNotificationService.receiveMessage();
    this.pushNotificationService.currentMessage.subscribe((message) => {
      console.log('app-message', message)
      if (message){
        const notificationDialog = this.dialog.open(NotificationComponent)
        notificationDialog.componentInstance.title = message.notification.title;
        notificationDialog.componentInstance.description = message.notification.body
      }
      this.message = message;
    });
  }
}
