import {createAction, props} from "@ngrx/store";
import {Chat} from "../models/chat";
import {create} from "ionicons/icons";
import {Message, Pagination} from "../models/message";

export interface ChatAction {
  type: string,
  payload?: any
}

export const GET_CHATS = '[CHAT] Get Chats'
export const GET_CHATS_SUCCESS = '[CHAT] Get Chats Success'
export const GET_CHATS_ERROR = '[CHAT] Get Chats Error'

export const SELECT_CHAT = '[CHAT] Select Chat'
export const GET_MESSAGES = '[CHAT] Get Messages'
export const GET_MESSAGES_SUCCESS = '[CHAT] Get Messages SUCCESS'
export const PUSH_MESSAGE_TO_CURRENT_CHAT = '[Chat] Push Message To Current Chat'
export const getChats = createAction(GET_CHATS, props<{ page: number }>())
export const getChatsSuccess = createAction(GET_CHATS_SUCCESS, props<{ data: Chat[] }>())
export const getChatsError = createAction(GET_CHATS_ERROR, props<{ error: string }>())

export const selectChat = createAction(SELECT_CHAT, props<{chat: Chat}>())

// messages
export const getMessages = createAction(GET_MESSAGES, props<{chatId: string}>())
export const getMessagesSuccess = createAction(GET_MESSAGES_SUCCESS, props<{data: Pagination<Message>}>())
export const pushMessage = createAction(PUSH_MESSAGE_TO_CURRENT_CHAT, props<{message: Message}>())
