import {Injectable} from "@angular/core";
import {deleteToken, getToken, Messaging, onMessage} from "@angular/fire/messaging";
import {BehaviorSubject, Observable, tap} from "rxjs";
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  currentMessage = new BehaviorSubject<any>(null);

  constructor(private messaging: Messaging, private httpClient: HttpClient) {}

  requestPermission() {
    return getToken(this.messaging, { vapidKey: 'BNXkEZA9KEhVHANU3oy-G2vNgt863pVMLHw5QAY4tjhTmzYuRkJqHYEfweShm_xy0IiAGCcCLmxCLSPdVnDEqnk' });
  }
  subscribe(token: string){
    return this.httpClient.post(`${environment.apiHost}/notifications/subscribe`, {
      userId: '1',
      token
    })
  }
  notifications(userId:string){
    return this.httpClient.get<any[]>(`${environment.apiHost}/notifications/user/${userId}`)
  }

  receiveMessage() {
    onMessage(this.messaging, (payload) => {
      console.log('Message received. ', payload);
      this.currentMessage.next(payload);
    });
  }
}
