import {Component, inject, Input} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent {

  readonly dialogRef = inject(MatDialogRef<NotificationComponent>);
  @Input() title!: string
  @Input() description!: string

  onNoClick(): void {
    this.dialogRef.close();
  }

}
