import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ChatService} from "../services/chat.service";
import {ChatAction, GET_CHATS, GET_MESSAGES, getChatsError, getChatsSuccess, getMessagesSuccess} from "./chat.actions";
import {catchError, exhaustMap, map} from "rxjs";

@Injectable()
export class ChatEffects {
  constructor(private actions$: Actions,
              private chatService: ChatService) {
  }

  getChats$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_CHATS),
        exhaustMap((chatAction: ChatAction) =>
          this.chatService.chats().pipe(
            map((response) => getChatsSuccess({data: response})),
            catchError((err) => {
              return [getChatsError({error: err})];
            }),
          ),
        ),
      )
    },
  )
  getMessages$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(GET_MESSAGES),
        exhaustMap((chatAction: any) =>
          this.chatService.chatMessages(chatAction.chatId).pipe(
            map((response) => getMessagesSuccess({data: response})),
            catchError((err) => {
              return [getChatsError({error: err})];
            }),
          ),
        ),
      )
    },
  )
}
