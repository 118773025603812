import {isDevMode, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {AngularFireMessagingModule} from "@angular/fire/compat/messaging";
import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
import {getMessaging, provideMessaging} from "@angular/fire/messaging";
import {StoreModule} from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {ChatEffects} from "./modules/chat/store/chat.effects";
import {appReducer} from "./store/app.reducers";
import {ChatService} from "./modules/chat/services/chat.service";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {environment} from "../environments/environment";
import {StoreDevtoolsModule} from "@ngrx/store-devtools";
import {NotificationComponent} from "./share/components/notification/notification.component";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatFormField} from "@angular/material/form-field";
import {MatButton} from "@angular/material/button";
import {MatInput} from "@angular/material/input";
import {FormsModule} from "@angular/forms";

@NgModule({
  declarations: [AppComponent, NotificationComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireMessagingModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([ChatEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: isDevMode(),
    }),
    MatDialogContent,
    MatFormField,
    MatDialogActions,
    MatButton,
    MatInput,
    MatDialogTitle,
    FormsModule,
    MatDialogClose
  ],
  providers: [
    ChatService,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    provideFirebaseApp(() => initializeApp({
      apiKey: "AIzaSyClwneiJtd5_h-6aURAiGgMBfrn7sm77R4",
      authDomain: "chatbotx-7772a.firebaseapp.com",
      projectId: "chatbotx-7772a",
      storageBucket: "chatbotx-7772a.appspot.com",
      messagingSenderId: "963780278817",
      appId: "1:963780278817:web:b6b67750f14a2641424160",
      measurementId: "G-4DC2QGFBH0"
    })),
    provideMessaging(() => getMessaging()),
    provideAnimationsAsync(),],

  bootstrap: [AppComponent],
})
export class AppModule {
}
