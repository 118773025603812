import {BaseChat, Chat} from "../models/chat";
import {createReducer, on} from "@ngrx/store";
import {
  ChatAction,
  getChats,
  getChatsSuccess,
  getMessages,
  getMessagesSuccess,
  pushMessage,
  selectChat
} from "./chat.actions";
import {Message, Pagination} from "../models/message";
export interface CurrentChat extends BaseChat{
  id: string,
  name: string,
  messages: Pagination<Message>
}
export interface ChatState {
  data: Chat[],
  currentChat: CurrentChat
}

export const initialChatState: ChatState = {
  data: [],
  currentChat: {
    id: "",
    name: "",
    messages: {
      total: 0,
      page: 0,
      limit: 0,
      totalPages: 0,
      data: []
    },
    from: {
      id: "",
      name: ""
    },
    recipient: {
      id: "",
      name: ""
    },
    supportCases: []
  }
}

const reducer = createReducer(
  initialChatState,
  on(getChats, (state, action): ChatState => ({
    ...state
  })),
  on(selectChat, (state, action): ChatState => ({
    ...state,
    currentChat: {
      ...action.chat,
      messages: {
        total: 0,
        page: 0,
        limit: 0,
        totalPages: 0,
        data: []
      },
    }
  })),
  on(getChatsSuccess, (state, action): ChatState => ({
    ...state,
    data: action.data
  })),
  // messages
  on(getMessages, (state, action): ChatState => ({
    ...state
  })),
  on(getMessagesSuccess, (state, action): ChatState => ({
    ...state,
    currentChat: {
      ...state.currentChat,
      messages: action.data,
    }
  })),
  on(pushMessage, (state, action): ChatState => ({
    ...state,
    currentChat: {
      ...state.currentChat,
      messages: {
        ...state.currentChat.messages,
        data: [...state.currentChat.messages.data, action.message]
      }
    }
  })),
)

export function chatReducer(state: ChatState, action: ChatAction) {
  return reducer(state, action)
}
